import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';
import swipePlugin from 'alpinejs-swipe';
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

window.Alpine = Alpine;
window.Swiper = Swiper;

Alpine.plugin(swipePlugin);
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(focus);

document.addEventListener('DOMContentLoaded', function () {
	const pageContent = document.querySelector('.page-content');
	const pageContentChildren = pageContent.children;
	for (let i = 0; i < pageContentChildren.length; i++) {
		pageContentChildren[i].classList.add('ramp-rectangle-bg');
	}

	const bgRampBlue = document.querySelectorAll('.page-content .bg-ramp-blue');
	bgRampBlue.forEach(function (el) {
		el.classList.add('ramp-rectangle-bg');
	});

	const elements = document.querySelectorAll('.ramp-rectangle-bg');
	elements.forEach(function (el) {
		if (el.classList.contains('no-rectangles-bg')) {
			return;
		}
		const randomX = Math.floor(Math.random() * 100);
		const randomY = Math.floor(Math.random() * 100);
		el.style.backgroundPosition = `${randomX}% ${randomY}%`;
	});
});
